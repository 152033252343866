<app-header [hidden]="showScanner"></app-header>
<div class="container" [hidden]="showScanner">
  <!-- signIn Form 01 -->
  <section class="SignInComp">
    <article>
      <p class="mb-0">{{ "SIGNIN.SIGN_IN" | translate }}</p>
      <figure class="text-center">
        <img src="../../../assets/images/care&share-01.png" />
      </figure>
    </article>
    <form [formGroup]="signInForm" (submit)="signInFormSubmit()">
      <div class="form-group">
        <input
          type="text"
          formControlName="mobile_no"
          maxlength="10"
          required
          readonly
          (keypress)="onlyNumbers($event)"
          [ngClass]="{
            'is-invalid': submittedForm && SignInFormControl.mobile_no.errors
          }"
        />
        <label>{{ "SIGNIN.MOBILE" | translate }}</label>
      </div>
      <div class="form-group pr">
        <input
          [type]="show1 ? 'text' : 'password'"
          formControlName="password"
          minlength="5"
          required
          (input)="passwordInput($event)"
          [ngClass]="{
            'is-invalid':
              (submittedForm && SignInFormControl.password.errors) ||
              passwordError
          }"
        />
        <label>{{ "SIGNIN.PASSWORD" | translate }}</label>
        <img
          src="../../../assets/images/eye.png"
          class="showPassImg"
          (click)="showPassword()"
          alt="eye"
        />
        <div *ngIf="passwordError" class="FieldError">
          {{ "SIGNIN.PASSWORD_NOT_MATCH" | translate }}
        </div>
      </div>
      <!-- <div class="remSec">
                <input type="checkbox" class="" id="exampleCheck1"
                (change)="rememberUser($event.target.checked)" [checked]="rememval == 'true'" />
                <span>Ghi nhớ đăng nhập</span>
                <label class="rememberUser">
                
                    <span class="checkmark"></span>

                </label>
            </div> -->
      <div class="styled-input-single remSec">
        <input
          type="checkbox"
          name="fieldset-2"
          id="radio2-example-three"
          (change)="rememberUser($event.target.checked)"
          [checked]="rememval == 'true'"
        />
        <label for="radio2-example-three">Ghi nhớ đăng nhập</label>
      </div>
      <div
        *ngIf="submittedForm && SignInFormControl?.password?.errors?.required"
        class="isinvalid"
      >
        <div class="invalidText mb-3">mật khẩu là bắt buộc</div>
      </div>
      <div>
        <button class="orangeBtn w-100" tabindex="0" [disabled]="submittedSignInForm">
          {{ "SIGNIN.SUBMIT" | translate }}
        </button>
      </div>
    </form>

    <div class="text-center mt-3">
      <p>
        {{ "SIGNIN.FORGOT_PASSWORD" | translate
        }}<a href="javascript:;" routerLink="/forgot-password">{{
          "SIGNIN.CLICK_HERE" | translate
        }}</a>
      </p>
    </div>
  </section>
</div>

<!-- signIn Form 02 -->
<!-- <section class="signInForm02">
<article>
    <p>{{'SIGNIN.SIGNIN02_MSG_TITLE' | translate}}</p>
    <p class="text-center mb-0">{{'SIGNIN.SIGNIN02_MSG_BODY01' | translate}}</p>
    <p class="text-center">{{'SIGNIN.SIGNIN02_MSG_BODY02' | translate}}</p>
    <div>
        <button class="orangeBtn w-100" tabindex="0" >{{'SIGNIN.SIGNIN02_MSG_BTN' | translate}}</button> 
    </div>
</article>
</section> -->
<!-- <button class="btn" (click)="openinvalidQrCodeModal()">open mdl</button> -->

<div
  bsModal
  #invalidQrCodeModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
  [config]="config"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content videoModalContainer">
      <div class="modal-body">
        <section class="checkMilkcodeMdl text-center">
          <button
            type="button"
            class="close pull-right closeBtn"
            aria-label="Close"
            (click)="invalidQrCodeHide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h2 class="blueTitle pt-3">
            {{ "INVALIDQR_CODE1.TITLE1" | translate }}
          </h2>
          <h2 class="blueTitle pt-0">
            {{ "INVALIDQR_CODE1.TITLE2" | translate }}
          </h2>
          <!-- <div>
                        <p class="pt-2 mb-0"> {{ 'INVALIDQR_CODE1.PARA1' | translate }}</p>
                        <a class="links d-block" routerLink=""> {{ 'INVALIDQR_CODE1.PARA2' | translate }}</a>
                        <p class="mb-0 pt-0"> {{ 'INVALIDQR_CODE1.PARA3' | translate }}</p>
                        <a class="pt-3 mb-4 links d-block"> {{ 'INVALIDQR_CODE1.LINK_TEXT' | translate }}</a>
                    </div> -->
          <div>
            <p class="pt-2 mb-0">
              {{ "INVALIDQR_CODE1.CODE2_PARA1" | translate }}
              <a class="links" (click)="openSimilacTermsandConditions()">
                {{ "INVALIDQR_CODE1.CODE2_LINK" | translate }}</a
              >
              {{ "INVALIDQR_CODE1.CODE2_PARA2" | translate }}
            </p>
          </div>

          <div class="blueCard mt-4">
            <p class="topText">
              {{ "INVALIDQR_CODE1.CARD_TEXT_TOP" | translate }}
            </p>
            <p class="colorBlackBold pt-2 mb-0">
              {{ "INVALIDQR_CODE1.CARD_PARA" | translate }}
            </p>
            <p class="colorBlackBold pt-0">
              {{ "INVALIDQR_CODE1.CARD_PARA1" | translate }}
            </p>
            <h2 class="blueTitle">
              <a class="telNum" href="tel:19001519">{{
                "INVALIDQR_CODE1.CARD_BOTTOM_NUMBER" | translate
              }}</a>
            </h2>
          </div>
        </section>
      </div>
    </div>
  </div>
</div>

<div
  bsModal
  #successQrCodeModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
  [config]="config"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content videoModalContainer">
      <div class="modal-body">
        <section class="qrCodeScanModal">
          <button
            type="button"
            class="close pull-right closeBtn"
            aria-label="Close"
            (click)="closeSuccessModal()"
          >
            <span aria-hidden="true">&times;</span>
          </button>

          <div class="contents">
            <h1 *ngIf="categoryCode == 'Grow'">
              {{ "QR_CODE_SCAN_POP_UP.TITLE" | translate }}
              {{ global_grow_Month_count }}
              {{ "QR_CODE_SCAN_POP_UP.TITLE1" | translate }}
            </h1>
            <h1 *ngIf="categoryCode == 'Similac'">
              {{ "QR_CODE_SCAN_POP_UP.TITLE" | translate }}
              {{ global_similac_Month_count }}
              {{ "QR_CODE_SCAN_POP_UP.TITLE1" | translate }}
            </h1>

            <h1 *ngIf="categoryCode == 'Ensure'">
              Cám ơn bạn đã quét mã QR lần thứ {{ global_ensure_Month_count }} trong
              tháng này.
            </h1>

            <h1 *ngIf="categoryCode == 'Glucerna'">
              Cám ơn bạn đã quét mã QR lần thứ {{ global_glucerna_Month_count }} trong
              tháng này.
            </h1>

            <h1 *ngIf="categoryCode == 'PediaSure'">
              Cám ơn bạn đã quét mã QR lần thứ {{ global_pediasure_Month_count }} trong
              tháng này.
            </h1>
            <!-- This section will shows only Pediasure, Ensure, Grow and Similac. Hides Glcerna regarding new product launch -->
            <div *ngIf="prodCategory == 'Grow' || prodCategory == 'Similac' || prodCategory == 'Ensure' || prodCategory == 'PediaSure' || prodCategory == 'Glucerna'">
              <p *ngIf="this.categoryCode == 'Grow'">
                {{ "QR_CODE_SCAN_POP_UP.DESC1" | translate }}
                {{ myDate | date: "dd/MM/yyyy" }}.
              </p>
              <p *ngIf="this.categoryCode == 'Similac'">
                {{ "QR_CODE_SCAN_POP_UP.DESC1A" | translate }}
                {{ myDate | date: "dd/MM/yyyy" }}.
              </p>

              <p *ngIf="this.categoryCode == 'Ensure'">
                Bạn đã nhận được 1 cơ hội tham gia vòng quay may mắn từ Ensure
                Lượt quay sẽ hết hạn vào ngày

                {{ myDate | date: "dd/MM/yyyy" }}.
              </p>

              <p *ngIf="this.categoryCode == 'Glucerna'">
                Bạn đã nhận được 1 cơ hội tham gia vòng quay may mắn từ Glucerna
                Lượt quay sẽ hết hạn vào ngày

                {{ myDate | date: "dd/MM/yyyy" }}.
              </p>

              <p *ngIf="this.categoryCode == 'PediaSure'">
                Bạn đã nhận được 1 cơ hội tham gia vòng quay may mắn từ PediaSure
                Lượt quay sẽ hết hạn vào ngày

                {{ myDate | date: "dd/MM/yyyy" }}.
              </p>

              <a
                
                href="javascript:;"
                class="orangeBtn"
                (click)="growOrSimilac()"
                >{{ "QR_CODE_SCAN_POP_UP.JOIN_THE_LUCKY_WHEEL" | translate }}</a
              >

              <p *ngIf="this.categoryCode == 'Grow'">
                {{ "QR_CODE_SCAN_POP_UP.DESC2" | translate }}
                {{ remain_grow_Month_count }}
                {{ "QR_CODE_SCAN_POP_UP.DESC3" | translate }}
              </p>
              <p *ngIf="this.categoryCode == 'Similac'">
                {{ "QR_CODE_SCAN_POP_UP.DESC2" | translate }}
                {{ remain_similac_Month_count }}
                {{ "QR_CODE_SCAN_POP_UP.DESC3A" | translate }}
              </p>

              <p *ngIf="this.categoryCode == 'Ensure'">
                Bạn vẫn còn

                {{ remain_ensure_Month_count }} cơ hội tham gia vòng quay may mắn từ
                Ensure trong tháng này
              </p>
              <p *ngIf="this.categoryCode == 'Glucerna'">
                Bạn vẫn còn

                {{ remain_glucerna_Month_count }} cơ hội tham gia vòng quay may mắn từ
                Glucerna trong tháng này
              </p>

              <p *ngIf="this.categoryCode == 'PediaSure'">
                Bạn vẫn còn

                {{ remain_pediasure_Month_count }} cơ hội tham gia vòng quay may mắn từ
                PediaSure trong tháng này
              </p>
            </div>
            

            <a *ngIf="prodCategory == 'Grow' || prodCategory == 'Similac' || prodCategory == 'Ensure' || prodCategory == 'PediaSure'" class="blueBtn" (click)="onClickScanner()">{{
              "QR_CODE_SCAN_POP_UP.SCAN_QR_CODE" | translate
            }}</a>
            <a *ngIf="prodCategory == 'Glucerna'" class="blueBtn" (click)="onClickScanner()">{{
              "QR_CODE_SCAN_POP_UP.SCAN_QR_CODE" | translate
            }}</a>

            <!-- New Card Design starts -->
            <div *ngIf="this.categoryCode == 'Glucerna'" style="position: relative;">
              <div style="margin:30px auto;" class="glucernaProgramContainer">
                <p style="position: relative;top: 5px;">
                  <strong class="glucernaProgramText1">CHƯƠNG TRÌNH MỚI SẮP RA MẮT!</strong>
                </p>
                <div class="glucernaInnerRectangle">
                  <strong class="glucernaProgramText2">QUÉT MÃ GLUCERNA<br>TÍCH ĐIỂM ĐỔI
                    QUÀ</strong>
                  <strong
                    style="font-weight: 900 !important;font-style: normal;letter-spacing: -0.06px;text-align: center;color: #fff;font-size:12.5px;margin-top: 10px;display: block;margin-bottom: 10px;">MUA
                    CÀNG NHIỀU, ĐIỂM CÀNG CAO, QUÀ CÀNG LỚN</strong>
                  <p style="color: #fff;"> Những lượt quay từ ngày <strong>01/10/2024</strong> chưa được sử dụng sẽ được
                    chuyển thành điểm thưởng tương ứng khi chương trình mới ra mắt.</p>
                </div>
                <strong class="glucernaProgramText1">HÃY ĐÓN CHỜ<br>CÙNG GLUCERNA NHÉ!</strong>

              </div>
              <div style="position:absolute;bottom:-100px;">
                <img class="img-fluid" src="../../../assets/images/giftGlucerna.png" alt="glucernaGift"
                  style="width: 100%;height: auto;object-fit: contain; " />
              </div>

            </div>
            <!-- !! New Card Design Ends -->
            <div style="margin-top: 100px;">
              <p class="py-4" *ngIf="prodCategory == 'Grow' || prodCategory == 'Similac'">
                {{ "OUT_OF_GIFT.CODE" | translate }}
              </p>
              <p class="py-4" *ngIf="prodCategory == 'Ensure'">ENS-C-506-21</p>
              <p class="py-4" *ngIf="prodCategory == 'Glucerna'">GLU-C-200-21</p>
              <p class="py-4" *ngIf="prodCategory == 'PediaSure'">PED-C-76-22</p>
            </div>
            
          </div>
        </section>
      </div>
    </div>
  </div>
</div>

<!-- qrcode1modal -->
<div
  bsModal
  #qrCode1Modal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
  [config]="config"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content" style="height: 200px">
      <img class="qrImage" src="../../assets/images/qrimage.png" />
      <div class="modal-body p-0">
        <div class="couponModalContainer qrCodeModalContainer">
          <ul class="voucherCardList">
            <li>
              <div class="voucherCard">
                <i class="closeBtn" (click)="closeQrCode1Modal()"></i>
                <p class="mt-2">Scratch and scan the second</p>
                <p>hidden QR code to earn points!</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- testModal -->
<div
  bsModal
  #testModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content" style="height: 200px">
      <img class="qrImage" src="../../assets/images/qrimage.png" />
      <div class="modal-body p-0">
        <div class="couponModalContainer qrCodeModalContainer">
          <ul class="voucherCardList">
            <li>
              <div class="voucherCard">
                <i class="closeBtn" (click)="closeTestModal()"></i>
                <p class="mt-2">Scratch and scan the second</p>
                <p>hidden QR code to earn points!</p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- <div
  bsModal
  #milkCodeModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
  [config]="config"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content videoModalContainer">
      <div class="modal-body">
        <section class="checkMilkcodeMdl text-center">
          <button
            type="button"
            class="close pull-right closeBtn"
            aria-label="Close"
            (click)="milkCodeHide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h2 class="blueTitle pt-3">{{ "MILK_CODE.TITLE1" | translate }}</h2>
          <h2 class="blueTitle pt-0">{{ "MILK_CODE.TITLE2" | translate }}</h2>
          <p class="mb-0 pt-0" style="font-size: 4.3vw">
            {{ "MILK_CODE.PARA" | translate }}<br />
            {{ "MILK_CODE.PARA2" | translate }}<br />
            {{ "MILK_CODE.PARA3" | translate }}<br />
            {{ "MILK_CODE.PARA4" | translate }}
          </p>
          < <p class=" mb-0 pt-0"> {{ 'MILK_CODE.PARA2' | translate }}</p>
                    <p class=" mb-0 pt-0"> {{ 'MILK_CODE.PARA3' | translate }}</p>
                    <p class=" mb-0 pt-0"> {{ 'MILK_CODE.PARA4' | translate }}</p> -->
          <!-- <button class="orangeBtn my-4" (click)="milkCodeHide()">
            {{ "MILK_CODE.BTN_TEXT" | translate }}
          </button>
          <a class="pt-3 mb-4 links d-block">
            {{ "MILK_CODE.LINK_TEXT" | translate }}</a
          >
          <div class="blueCard mt-4">
            <p class="topText">{{ "MILK_CODE.CARD_TEXT_TOP" | translate }}</p>
            <p class="colorBlackBold pt-2 mb-0">
              {{ "MILK_CODE.CARD_PARA" | translate }}
            </p>
            <p class="colorBlackBold pt-0">
              {{ "MILK_CODE.CARD_PARA1" | translate }}
            </p>
            <h2 class="blueTitle" routerLink="/scanner">
              {{ "MILK_CODE.CARD_BOTTOM_NUMBER" | translate }}
            </h2>
          </div>
          <p
            class="py-4"
            *ngIf="prodCategory == 'Grow' || prodCategory == 'Similac'"
          >
            {{ "OUT_OF_GIFT.CODE" | translate }}
          </p>

          <p class="py-4" *ngIf="prodCategory == 'Ensure'">ENS-C-506-21</p>
          <p class="py-4" *ngIf="prodCategory == 'Glucerna'">GLU-C-200-21</p>
          <p class="py-4" *ngIf="prodCategory == 'PediaSure'">PED-C-76-22</p>
        </section>
      </div>
    </div> -->
  <!-- </div> -->
<div
  bsModal
  #milkCodeModal="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
  [config]="config"
>
  <div class="modal-dialog modal-sm">
    <div
      class="modal-content videoModalContainer"
      style="background-image: linear-gradient(to top, #ffce4185, #fcd64e80);"
    >
      <div class="modal-body">
        <section class="checkMilkcodeMdl text-center">
          <button
            type="button"
            class="close pull-right closeBtn"
            aria-label="Close"
            (click)="milkCodeHide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <img
            src="../../../assets/images/img1.png"
            alt="Similac - Thank you Mom for becoming a member of Care & Share Home"
            class="modal-image"
          />
          <div class="giftcontent-1">
            <img
              class="gift-box-image"
              src="../../../assets/images/giftbox.png"
              alt="gift box"
            />
            <strong>Cẩm Nang Lần Đầu Làm Mẹ:</strong>
            mách mẹ bộ bí kíp chăm con nhàn tênh, được chia sẻ bởi các chuyên
            gia và mẹ bỉm sữa giàu kinh nghiệm.
          </div>
          <div class="container-box">
            <img class="rectangle-box" src="../../../assets/images/img02.png" />
            <div class="cta-btn1">
              <a
                href="https://www.family.abbott/content/dam/an/familyabbott/vn-vi/similac/eazy-ebook/sim_lp_eazy-ebook_20231110_pdf01.pdf"
                target="_blank"
              >
                <img
                  src="../../../assets/images/cta.png" alt="cta"
                />
              </a>
            </div>
          </div>
          <div class="giftcontent-2">
            <img
              class="gift-box-image"
              src="../../../assets/images/giftbox.png"
              alt="gift box"
            />
            <strong>Cẩm Nang Chăm Sóc Mẹ Sinh Mổ:</strong>
            chia sẻ đến mẹ những tips hữu ích về cách chăm sóc bé sinh mổ cũng
            như cách giúp mẹ sinh mổ hồi phục sau sinh.
          </div>

          <div class="container-box1">
            <img
              class="rectangle-box1"
              src="../../../assets/images/img3.png"
            />
            <div class="cta-btn2">
              <a href="https://www.family.abbott/content/dam/an/familyabbott/vn-vi/similac/c-section-ebook/sim_pl_c-section-ebook_20231208_pdf02.pdf" target="_blank">
                <img src="../../../assets/images/cta.png" alt="cta"/>
            </a>
            </div>
          </div>
          <span class="sim-c" aria-hidden="true"> SIM-C-484-23 </span>
        </section>
      </div>
    </div>
  </div>
</div>

<div
  bsModal
  #termsSimilac="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
  [config]="config"
>
  <div class="modal-dialog modal-sm">
    <div class="modal-content videoModalContainer">
      <div class="modal-body rewardsLoginOut px-0">
        <section class="similacTermsMdl text-center">
          <button
            type="button"
            class="close pull-right closeBtn"
            aria-label="Close"
            (click)="termsSimilacHide()"
          >
            <span aria-hidden="true">&times;</span>
          </button>
          <h3 class="text-uppercase textSecondaryBlue">Thể lệ chương trình</h3>

          <!-- <div class="giftList p-0 mt-3">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link active"
                  id="grow-tab1"
                  data-toggle="tab"
                  href="#grow1"
                  role="tab"
                  aria-controls="home"
                  aria-selected="true"
                  >{{ "REWARDS_LOGIN.ABBOTT_GROW" | translate }}</a
                >
              </li>
              <li class="nav-item" role="presentation">
                <a
                  class="nav-link"
                  id="similac-tab1"
                  data-toggle="tab"
                  href="#similac1"
                  role="tab"
                  aria-controls="profile"
                  aria-selected="false"
                  >{{ "REWARDS_LOGIN.SIMILAC" | translate }}</a
                >
              </li>
            </ul>
            <div class="tab-content" id="myTabContent1">
              <div
                id="grow1"
                role="tabpanel"
                aria-labelledby="grow-tab1"
                class="tab-pane fade show active"
              >
                <div class="innerContent">
                  <section
                    *ngIf="growtermsContent"
                    [innerHTML]="growtermsContent | safe: 'html'"
                  ></section>
                </div>
              </div>
              <div
                id="similac1"
                role="tabpanel"
                aria-labelledby="similac-tab1"
                class="tab-pane fade show"
              >
                <div class="innerContent">
                  <section
                    *ngIf="termsContent"
                    [innerHTML]="termsContent | safe: 'html'"
                  ></section>
                </div>
              </div>
            </div>
          </div> -->
          <div class="giftList p-0 mt-3">
            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <a class="nav-link active" id="grow-tab1" 
                        data-toggle="tab" href="#grow1" role="tab" aria-controls="home"
                        aria-selected="true">{{ 'REWARDS_LOGIN.ABBOTT_GROW' |
                        translate }}</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="similac-tab1"
                        data-toggle="tab" href="#similac1" role="tab" aria-controls="profile"
                        aria-selected="false">{{ 'REWARDS_LOGIN.SIMILAC'
                        | translate }}</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="pediasure-tab1"
                        data-toggle="tab" href="#pediasure1" role="tab" aria-controls="profile"
                        aria-selected="false">PediaSure</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="ensure-tab1"
                        data-toggle="tab" href="#ensure1" role="tab" aria-controls="profile"
                        aria-selected="false">Ensure Gold</a>
                </li>
                <li class="nav-item" role="presentation">
                    <a class="nav-link" id="glucerna-tab1"
                        data-toggle="tab" href="#glucerna1" role="tab" aria-controls="profile"
                        aria-selected="false">Glucerna</a>
                </li>
               
            </ul>
            <div class="tab-content" id="myTabContent1">
                <div id="grow1" role="tabpanel" aria-labelledby="grow-tab1" class="tab-pane fade show active" >
                    <div class="innerContent">
                        <section *ngIf="growtermsContent" [innerHTML]="growtermsContent | safe: 'html'"></section>
                    </div>

                </div>
                <div id="similac1" role="tabpanel" aria-labelledby="similac-tab1" class="tab-pane fade show">
                    <div class="innerContent">
                        <section *ngIf="termsContent" [innerHTML]="termsContent | safe: 'html'"></section>
                    </div>
                </div>
                <div id="pediasure1" role="tabpanel" aria-labelledby="pediasure-tab1" class="tab-pane fade show" >
                    <div class="innerContent">
                        <section *ngIf="pediasureltermsContent" [innerHTML]="pediasureltermsContent | safe: 'html'"></section>
                    </div>

                </div>
                <div id="ensure1" role="tabpanel" aria-labelledby="ensure-tab1" class="tab-pane fade show" >
                    <div class="innerContent">
                        <section *ngIf="ensuretermsContent" [innerHTML]="ensuretermsContent | safe: 'html'"></section>
                    </div>

                </div>
                <div id="glucerna1" role="tabpanel" aria-labelledby="glucerna-tab1" class="tab-pane fade show" >
                    <div class="innerContent">
                        <section *ngIf="glucernatermsContent" [innerHTML]="glucernatermsContent | safe: 'html'"></section>
                    </div>

                </div>
            </div>
        </div>
        </section>
      </div>
    </div>
  </div>
</div>

<div
  bsModal
  #addMoreMembers="bs-modal"
  class="modal fade"
  tabindex="-1"
  role="dialog"
  aria-labelledby="dialog-child-name"
  [config]="config"
>
  <div class="modal-dialog modal-sm addMoreMembersOut">
    <div class="modal-content">
      <div class="modal-body">
        <button
          type="button"
          class="close pull-right closeBtn"
          aria-label="Close"
          (click)="addMoreMembersClose()"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <div class="body">
          <section class="productChoice" *ngIf="saveInfo">
            <h2 class="mb-4">
              Cảm ơn bạn đã tin tưởng <br />lựa chọn sản phẩm của Abbott.
            </h2>
            <p class="text-center mb-4">
                Vui lòng <strong>chọn người sử dụng sản phẩm này </strong> <br />
                để chúng tôi có thể mang đến những <br />
                thông tin và lợi ích phù hợp với nhu cầu của bạn.
              
            </p>

            <div class="row">
              <!-- user profile -->

              <div class="profileDetails col-6">
                <i
                  id="showActive"
                  *ngIf="parentUserImage == '' || parentUserImage == undefined || parentUserImage == null"
                  class="userProfile"
                  (click)="clickProfile(parentUserImage)"
                  [ngClass]="statusProfile == true ? 'active' : ''"
                ></i>
                <i class="imageWidth" *ngIf="parentUserImage">
                  <img
                    id="showActive1"
                    (click)="clickProfile(parentUserImage)"
                    [src]="parentUserImage"
                    alt="profile bg"
                    [ngClass]="statusProfile == true ? 'active' : ''"
                  />
                </i>
                <h6>Tôi</h6>
              </div>

              <!-- user profile -->

              <div
                class="col-6"
                *ngFor="let child of globalChildArray; let i = index"
              >
                <div *ngIf="child.child == 'present'" class="profileDetails">
                  <!-- <i class="userProfile" (click)="clickEvent(i, child)" [ngClass]="status[i] == true ? 'active' : ''"></i> -->

                  <!-- <i class="userProfile" *ngIf="child.display_childImage == 'undefined'"  (click)="clickEvent(i, child)"  [ngClass]="status[i] == true ? 'active' : ''"></i>
                                    <i class="imageWidth" *ngIf="child.display_childImage != 'undefined'" (click)="clickEvent(i, child)"  [ngClass]="status[i] == true ? 'active' : ''">
                                        <img [src]="child.display_childImage" alt="profile bg" [ngClass]="status[i] == true ? 'active' : ''"/>
                                    </i> -->

                  <i
                    class="userProfile"
                    *ngIf="child.child_image == ''"
                    (click)="clickEvent(i, child)"
                    [ngClass]="status[i] == true ? 'active' : ''"
                  ></i>
                  <i
                    class="imageWidth"
                    *ngIf="child.child_image != ''"
                    (click)="clickEvent(i, child)"
                  >
                    <img
                      [src]="child.display_childImage"
                      alt="profile bg"
                      [ngClass]="status[i] == true ? 'active' : ''"
                    />
                  </i>

                  <h6>{{ child.name }}</h6>
                </div>

                <div
                  *ngIf="child.child == 'absent'"
                  class="profileDetails"
                  (click)="onClickSaveInfoOne(i)"
                >
                  <a class="moreBtn">+</a>
                  <h6>Thêm</h6>
                </div>
              </div>
            </div>

            <button
              class="orangeBtn"
              (click)="addTranscationForChild()"
              [disabled]="!enableAdd"
              [ngClass]="!enableAdd ? 'disablebtn' : 'enablebtn'"
            >
              {{ "ADD_MORE_MEMBERS.SAVE_INFORMATION" | translate }}
            </button>
          </section>
          <!-- form one -->
          <section class="addMoreMember" *ngIf="hideAddMoreMember">
            <a class="backBtn" (click)="backBtnOne()"
              ><img src="../../../assets/images/back.png" alt="back"
            /></a>
            <h2>{{ "ADD_MORE_MEMBERS.ADD_MORE_MEMBERS" | translate }}</h2>
            <!-- <i *ngIf="!finalUserImage"><img src="../../../assets/images/profile_bg.png" alt="profile bg" /></i>
                        <i *ngIf="finalUserImage"><img [src]="finalUserImage" alt="profile bg" /></i> -->

            <i *ngIf="!uploadedImage"
              ><img
                src="../../../assets/images/profile_bg.png"
                alt="profile bg"
            /></i>
            <i *ngIf="uploadedImage"
              ><img [src]="uploadedImage" alt="profile bg"
            /></i>

            <!-- <i><img src="../../../assets/images/profile_bg.png" alt="profile bg" /></i> -->
            <div class="text-center">
              <a class="blueBtn">{{
                "ADD_MORE_MEMBERS.CHANGE_AVATAR" | translate
              }}</a>

              <form>
                <div class="imageUploadWrap">
                  <label class="imageUploadLabel edit" for="upload">
                    <input
                      id="upload"
                      type="file"
                      name="profile"
                      (change)="fileChangeEvent($event)"
                    />
                  </label>
                  <ngx-popup
                    [(ngModel)]="visible"
                    [ngModelOptions]="{ standalone: true }"
                  >
                    <image-cropper
                      [imageChangedEvent]="imageChangedEvent"
                      [maintainAspectRatio]="true"
                      [containWithinAspectRatio]="containWithinAspectRatio"
                      [aspectRatio]="3 / 3"
                      [resizeToWidth]="256"
                      [cropperMinWidth]="128"
                      [onlyScaleDown]="true"
                      [roundCropper]="false"
                      [canvasRotation]="canvasRotation"
                      [transform]="transform"
                      [alignImage]="'left'"
                      [style.display]="showCropper ? null : 'none'"
                      format="png"
                      (imageCropped)="imageCropped($event)"
                      (imageLoaded)="imageLoaded()"
                      (cropperReady)="cropperReady($event)"
                      (loadImageFailed)="loadImageFailed()"
                    ></image-cropper>
                    <button class="uploadImageBtn" (click)="uploadImage()">
                      upload
                    </button>
                  </ngx-popup>
                </div>
              </form>
            </div>
            <div class="productUserOut">
              <h3>
                {{ "ADD_MORE_MEMBERS.PRODUCT_USER_INFORMATION" | translate }}
              </h3>
              <form
                [formGroup]="addMembersForm"
                (ngSubmit)="onSubmitaddMembersForm()"
              >
                <!-- Grow and Pediasure -->
                <!-- <p>Grow /Pediasure</p> -->
                <div
                  *ngIf="prodCategory == 'Grow' || prodCategory == 'PediaSure'"
                >
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <input
                          type="text"
                          required
                          formControlName="surName"
                          [ngClass]="{
                            'is-invalid':
                              submitted && addMembersFormVal.surName.errors
                          }"
                        />
                        <label>{{
                          "ADD_MORE_MEMBERS.SURNAME" | translate
                        }}</label>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <input
                          type="text"
                          required
                          formControlName="name"
                          [ngClass]="{
                            'is-invalid':
                              submitted && addMembersFormVal.name.errors
                          }"
                        />
                        <label>{{ "ADD_MORE_MEMBERS.NAME" | translate }}</label>
                      </div>
                    </div>
                  </div>
                  <div
                    class="nonMilkCode"
                    
                  >
                    <h6>{{ "ADD_MORE_MEMBERS.DOB" | translate }}</h6>
                    <div class="row">
                      <div class="col-4">
                        <div class="form-group">
                          <select
                            class="form-control"
                            placement="bottom"
                            formControlName="day"
                            [ngClass]="{
                              'is-invalid':
                                submitted && addMembersFormVal.day.errors
                            }"
                          >
                            <option value="" selected>Ngày</option>
                            <option>01</option>
                            <option>02</option>
                            <option>03</option>
                            <option>04</option>
                            <option>05</option>
                            <option>06</option>
                            <option>07</option>
                            <option>08</option>
                            <option>09</option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option>
                            <option>13</option>
                            <option>14</option>
                            <option>15</option>
                            <option>16</option>
                            <option>17</option>
                            <option>18</option>
                            <option>19</option>
                            <option>20</option>
                            <option>21</option>
                            <option>22</option>
                            <option>23</option>
                            <option>24</option>
                            <option>25</option>
                            <option>26</option>
                            <option>27</option>
                            <option>28</option>
                            <option>29</option>
                            <option>30</option>
                            <option>31</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group">
                          <select
                            class="form-control"
                            placement="bottom"
                            formControlName="month"
                            [ngClass]="{
                              'is-invalid':
                                submitted && addMembersFormVal.month.errors
                            }"
                          >
                            <option value="" selected>Tháng</option>
                            <option>01</option>
                            <option>02</option>
                            <option>03</option>
                            <option>04</option>
                            <option>05</option>
                            <option>06</option>
                            <option>07</option>
                            <option>08</option>
                            <option>09</option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option>
                            <!-- <option>tháng Giêng</option>
                                <option>tháng 2</option>
                                <option>tháng Ba</option>
                                <option>Tháng tư</option>
                                <option>có thể</option>
                                <option>Tháng sáu</option>
                                <option>Tháng bảy</option>
                                <option>tháng Tám</option>
                                <option>Tháng Chín</option>
                                <option>Tháng Mười</option>
                                <option>Tháng mười một</option>
                                <option>Tháng mười hai</option> -->
                          </select>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group">
                          <select
                            class="form-control"
                            placement="bottom"
                            formControlName="year"
                            [ngClass]="{
                              'is-invalid':
                                submitted && addMembersFormVal.year.errors
                            }"
                          >
                            <option value="" selected>Năm</option>
                            <option
                              *ngFor="let year of yearList"
                              [value]="year"
                            >
                              {{ year }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="milkCode" *ngIf="milkCode == 1">
                    <h6>{{ "ADD_MORE_MEMBERS.BABYS_AGE" | translate }}</h6>
                    <div class="form-group">
                      <select
                        class="form-control"
                        placement="bottom"
                        formControlName="babyAge"
                        [ngClass]="{
                          'is-invalid':
                            submitted && addMembersFormVal.babyAge.errors
                        }"
                      >
                        <option value="" selected>6-12 tháng tuổi</option>
                        <option value="" disabled selected>
                          chọn bên dưới
                        </option>
                        <option>0 - 6 tháng tuổi</option>
                        <option>6 - 12 tháng tuổi</option>
                        <option>12 - 18 tháng tuổi</option>
                        <option>18 - 24 tháng tuổi</option>
                      </select>
                    </div>
                  </div> -->
                </div>
                <!-- Similac -->
                <!-- <p>Similac</p> -->
                <div *ngIf="prodCategory == 'Similac'">
                  <div class="form-group mb-2">
                    <select
                      class="form-control"
                      (change)="momtype($event)"
                      formControlName="similacmomtype"
                    >
                      <option value="" selected disabled>
                        Tôi đang mang thai / Tôi đã có con
                      </option>
                      <option>Tôi đang mang thai</option>
                      <option>Tôi đã có con</option>
                    </select>
                  </div>

                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <input
                          type="text"
                          [required]="!isPregnent"
                          formControlName="surName"
                          [ngClass]="{
                            'is-invalid':
                              !isPregnent &&
                              submitted &&
                              addMembersFormVal.surName.errors
                          }"
                        />
                        <label *ngIf="isPregnent">Họ của bé (tuỳ chọn)</label>
                        <label *ngIf="!isPregnent">Họ của bé </label>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <input
                          type="text"
                          [required]="!isPregnent"
                          formControlName="name"
                          [ngClass]="{
                            'is-invalid':
                              !isPregnent &&
                              submitted &&
                              addMembersFormVal.name.errors
                          }"
                        />
                        <label *ngIf="isPregnent">Tên của bé (tuỳ chọn)</label>
                        <label *ngIf="!isPregnent">Tên của bé </label>
                      </div>
                    </div>
                  </div>
                  <div class="nonMilkCode">
                    <h6 *ngIf="!isPregnent">Ngày sinh của bé</h6>
                    <h6 *ngIf="isPregnent">Ngày dự sinh của mẹ</h6>
                    <div class="row">
                      <div class="col-4">
                        <div class="form-group">
                          <select
                            class="form-control"
                            placement="bottom"
                            formControlName="day"
                            [ngClass]="{
                              'is-invalid':
                                submitted && addMembersFormVal.day.errors
                            }"
                          >
                            <option value="" selected>Ngày</option>
                            <option>01</option>
                            <option>02</option>
                            <option>03</option>
                            <option>04</option>
                            <option>05</option>
                            <option>06</option>
                            <option>07</option>
                            <option>08</option>
                            <option>09</option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option>
                            <option>13</option>
                            <option>14</option>
                            <option>15</option>
                            <option>16</option>
                            <option>17</option>
                            <option>18</option>
                            <option>19</option>
                            <option>20</option>
                            <option>21</option>
                            <option>22</option>
                            <option>23</option>
                            <option>24</option>
                            <option>25</option>
                            <option>26</option>
                            <option>27</option>
                            <option>28</option>
                            <option>29</option>
                            <option>30</option>
                            <option>31</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group">
                          <select
                            class="form-control"
                            placement="bottom"
                            formControlName="month"
                            [ngClass]="{
                              'is-invalid':
                                submitted && addMembersFormVal.month.errors
                            }"
                          >
                            <option value="" selected>Tháng</option>
                            <option>01</option>
                            <option>02</option>
                            <option>03</option>
                            <option>04</option>
                            <option>05</option>
                            <option>06</option>
                            <option>07</option>
                            <option>08</option>
                            <option>09</option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option>
                            <!-- <option>tháng Giêng</option>
                                                    <option>tháng 2</option>
                                                    <option>tháng Ba</option>
                                                    <option>Tháng tư</option>
                                                    <option>có thể</option>
                                                    <option>Tháng sáu</option>
                                                    <option>Tháng bảy</option>
                                                    <option>tháng Tám</option>
                                                    <option>Tháng Chín</option>
                                                    <option>Tháng Mười</option>
                                                    <option>Tháng mười một</option>
                                                    <option>Tháng mười hai</option> -->
                          </select>
                        </div>
                      </div>
                      <div class="col-4">
                        <div class="form-group">
                          <select
                            *ngIf="isPregnent"
                            class="form-control"
                            placement="bottom"
                            formControlName="year"
                            [ngClass]="{
                              'is-invalid':
                                submitted && addMembersFormVal.year.errors
                            }"
                          >
                            <option value="" selected>Năm</option>
                            <option
                              [hidden]="i >= 2"
                              *ngFor="let year2 of motherDOByear; let i = index"
                              [value]="year2"
                            >
                              {{ year2 }}
                            </option>
                          </select>
                          <select
                            *ngIf="!isPregnent"
                            class="form-control"
                            placement="bottom"
                            formControlName="year"
                            [ngClass]="{
                              'is-invalid':
                                submitted && addMembersFormVal.year.errors
                            }"
                          >
                            <option value="" selected>Năm</option>
                            <option
                              *ngFor="let year of yearList"
                              [value]="year"
                            >
                              {{ year }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Glucerna -->
                <!-- <p>Glucerna</p> -->
                <div *ngIf="prodCategory == 'Glucerna'">
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <input
                          type="text"
                          required
                          formControlName="surName"
                          [ngClass]="{
                            'is-invalid':
                              submitted && addMembersFormVal.surName.errors
                          }"
                        />
                        <label>{{
                          "ADD_MORE_MEMBERS.SURNAME" | translate
                        }}</label>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <input
                          type="text"
                          required
                          formControlName="name"
                          [ngClass]="{
                            'is-invalid':
                              submitted && addMembersFormVal.name.errors
                          }"
                        />
                        <label>{{ "ADD_MORE_MEMBERS.NAME" | translate }}</label>
                      </div>
                    </div>
                  </div>
                  <div class="form-group mb-2">
                    <select
                      class="form-control"
                      id="exampleFormControlSelect1"
                      formControlName="typeofdiabetes"
                      [ngClass]="{
                        'is-invalid':
                          submitted && addMembersFormVal.typeofdiabetes.errors
                      }"
                    >
                      <option value="" selected disabled>
                        Tuýp đái tháo đường
                      </option>
                      <option>Tuýp 1</option>
                      <option>Tuýp 2</option>
                    </select>
                  </div>
                  <div class="nonMilkCode">
                    <h6 class="mt-2">Sinh nhật</h6>
                    <div class="row">
                      <div class="col-6">
                        <div class="form-group">
                          <select
                            class="form-control"
                            placement="bottom"
                            formControlName="day"
                            [ngClass]="{
                              'is-invalid':
                                submitted && addMembersFormVal.day.errors
                            }"
                          >
                            <option value="" selected>Ngày (tuỳ chọn)</option>
                            <option>01</option>
                            <option>02</option>
                            <option>03</option>
                            <option>04</option>
                            <option>05</option>
                            <option>06</option>
                            <option>07</option>
                            <option>08</option>
                            <option>09</option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option>
                            <option>13</option>
                            <option>14</option>
                            <option>15</option>
                            <option>16</option>
                            <option>17</option>
                            <option>18</option>
                            <option>19</option>
                            <option>20</option>
                            <option>21</option>
                            <option>22</option>
                            <option>23</option>
                            <option>24</option>
                            <option>25</option>
                            <option>26</option>
                            <option>27</option>
                            <option>28</option>
                            <option>29</option>
                            <option>30</option>
                            <option>31</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <select
                            class="form-control"
                            placement="bottom"
                            formControlName="month"
                            [ngClass]="{
                              'is-invalid':
                                submitted && addMembersFormVal.month.errors
                            }"
                          >
                            <option value="" selected>Tháng (tuỳ chọn)</option>
                            <option>01</option>
                            <option>02</option>
                            <option>03</option>
                            <option>04</option>
                            <option>05</option>
                            <option>06</option>
                            <option>07</option>
                            <option>08</option>
                            <option>09</option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option>
                            <!-- <option>tháng Giêng</option>
                                                        <option>tháng 2</option>
                                                        <option>tháng Ba</option>
                                                        <option>Tháng tư</option>
                                                        <option>có thể</option>
                                                        <option>Tháng sáu</option>
                                                        <option>Tháng bảy</option>
                                                        <option>tháng Tám</option>
                                                        <option>Tháng Chín</option>
                                                        <option>Tháng Mười</option>
                                                        <option>Tháng mười một</option>
                                                        <option>Tháng mười hai</option> -->
                          </select>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <select
                            class="form-control"
                            placement="bottom"
                            formControlName="year"
                            [ngClass]="{
                              'is-invalid':
                                submitted && addMembersFormVal.year.errors
                            }"
                          >
                            <option value="" selected>Năm</option>
                            <option
                              *ngFor="let year1 of yearList1"
                              [value]="year1"
                            >
                              {{ year1 }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!-- Ensure -->
                <!-- <p>Ensure</p> -->
                <div *ngIf="prodCategory == 'Ensure'">
                  <div class="row">
                    <div class="col-6">
                      <div class="form-group">
                        <input
                          type="text"
                          required
                          formControlName="surName"
                          [ngClass]="{
                            'is-invalid':
                              submitted && addMembersFormVal.surName.errors
                          }"
                        />
                        <label>{{
                          "ADD_MORE_MEMBERS.SURNAME" | translate
                        }}</label>
                      </div>
                    </div>
                    <div class="col-6">
                      <div class="form-group">
                        <input
                          type="text"
                          required
                          formControlName="name"
                          [ngClass]="{
                            'is-invalid':
                              submitted && addMembersFormVal.name.errors
                          }"
                        />
                        <label>{{ "ADD_MORE_MEMBERS.NAME" | translate }}</label>
                      </div>
                    </div>
                  </div>

                  <div class="nonMilkCode">
                    <h6>Sinh nhật</h6>
                    <div class="row">
                      <div class="col-6">
                        <div class="form-group">
                          <select
                            class="form-control"
                            placement="bottom"
                            formControlName="day"
                            [ngClass]="{
                              'is-invalid':
                                submitted && addMembersFormVal.day.errors
                            }"
                          >
                            <option value="" selected>Ngày (tuỳ chọn)</option>
                            <option>01</option>
                            <option>02</option>
                            <option>03</option>
                            <option>04</option>
                            <option>05</option>
                            <option>06</option>
                            <option>07</option>
                            <option>08</option>
                            <option>09</option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option>
                            <option>13</option>
                            <option>14</option>
                            <option>15</option>
                            <option>16</option>
                            <option>17</option>
                            <option>18</option>
                            <option>19</option>
                            <option>20</option>
                            <option>21</option>
                            <option>22</option>
                            <option>23</option>
                            <option>24</option>
                            <option>25</option>
                            <option>26</option>
                            <option>27</option>
                            <option>28</option>
                            <option>29</option>
                            <option>30</option>
                            <option>31</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-6">
                        <div class="form-group">
                          <select
                            class="form-control"
                            placement="bottom"
                            formControlName="month"
                            [ngClass]="{
                              'is-invalid':
                                submitted && addMembersFormVal.month.errors
                            }"
                          >
                            <option value="" selected>Tháng (tuỳ chọn)</option>
                            <option>01</option>
                            <option>02</option>
                            <option>03</option>
                            <option>04</option>
                            <option>05</option>
                            <option>06</option>
                            <option>07</option>
                            <option>08</option>
                            <option>09</option>
                            <option>10</option>
                            <option>11</option>
                            <option>12</option>
                            <!-- <option>tháng Giêng</option>
                                                        <option>tháng 2</option>
                                                        <option>tháng Ba</option>
                                                        <option>Tháng tư</option>
                                                        <option>có thể</option>
                                                        <option>Tháng sáu</option>
                                                        <option>Tháng bảy</option>
                                                        <option>tháng Tám</option>
                                                        <option>Tháng Chín</option>
                                                        <option>Tháng Mười</option>
                                                        <option>Tháng mười một</option>
                                                        <option>Tháng mười hai</option> -->
                          </select>
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <select
                            class="form-control"
                            placement="bottom"
                            formControlName="year"
                            [ngClass]="{
                              'is-invalid':
                                submitted && addMembersFormVal.year.errors
                            }"
                          >
                            <option value="" selected>Năm</option>
                            <option
                              *ngFor="let year1 of yearList1"
                              [value]="year1"
                            >
                              {{ year1 }}
                            </option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="checkbox">
                  <input
                    type="checkbox"
                    name="checktest1"
                    id="checktest1"
                    formControlName="termsAndConditions"
                    [ngClass]="{
                      'is-invalid':
                        submitted && addMembersFormVal.termsAndConditions.errors
                    }"
                    required
                  />
                  <label for="checktest1">{{
                    "ADD_MORE_MEMBERS.TERMS_AND_CONDITIONS" | translate
                  }}</label>
                </div>
                <p>{{ "ADD_MORE_MEMBERS.INCASE_PARTICIPANT" | translate }}</p>
                <button  type="submit" class="orangeBtn">
                  {{ "ADD_MORE_MEMBERS.ADD_MEMBERS" | translate }}
                </button>
              </form>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
</div>

<div bsModal #tryagainSmomModal="bs-modal" class="modal fade" tabindex="-1"
role="dialog" aria-labelledby="dialog-child-name" [config]="config">
     <div class="modal-dialog modal-sm">
       <div class="modal-content videoModalContainer">
             <div class="modal-body">
                <section class="checkMilkcodeMdl text-center">
                   
                    <h2 class="blueTitle pt-3">có gì đó không ổn!</h2>
                   
                    <button class="orangeBtn my-4" (click)="addTransactionForSMOM()"> Thử lại</button>

                        <div class="blueCard mt-4">
                            <p class="topText"> {{ 'INVALIDQR_CODE1.CARD_TEXT_TOP' | translate }}</p>
                            <p class="colorBlackBold pt-2 mb-0">{{ 'INVALIDQR_CODE1.CARD_PARA' | translate }}</p>
                            <p class="colorBlackBold pt-0">{{ 'INVALIDQR_CODE1.CARD_PARA1' | translate }}</p>
                            <h2 class="blueTitle"><a class="telNum" href="tel:19001519">{{ 'INVALIDQR_CODE1.CARD_BOTTOM_NUMBER' | translate }}</a></h2>
                        </div>
                </section>
            </div>
        </div>
    </div>
</div>

<div bsModal #tryagaiinAddTransModal="bs-modal" class="modal fade" tabindex="-1"
role="dialog" aria-labelledby="dialog-child-name" [config]="config">
     <div class="modal-dialog modal-sm">
       <div class="modal-content videoModalContainer">
             <div class="modal-body">
                <section class="checkMilkcodeMdl text-center">
                   
                    <h2 class="blueTitle pt-3">có gì đó không ổn!</h2>
                   
                    <button class="orangeBtn my-4" (click)="addTransactioninApp()"> Thử lại</button>

                        <div class="blueCard mt-4">
                            <p class="topText"> {{ 'INVALIDQR_CODE1.CARD_TEXT_TOP' | translate }}</p>
                            <p class="colorBlackBold pt-2 mb-0">{{ 'INVALIDQR_CODE1.CARD_PARA' | translate }}</p>
                            <p class="colorBlackBold pt-0">{{ 'INVALIDQR_CODE1.CARD_PARA1' | translate }}</p>
                            <h2 class="blueTitle"><a class="telNum" href="tel:19001519">{{ 'INVALIDQR_CODE1.CARD_BOTTOM_NUMBER' | translate }}</a></h2>
                        </div>
                </section>
            </div>
        </div>
    </div>
</div>


<div bsModal #tryagaiinChildAddTransModal="bs-modal" class="modal fade" tabindex="-1"
role="dialog" aria-labelledby="dialog-child-name" [config]="config">
     <div class="modal-dialog modal-sm">
       <div class="modal-content videoModalContainer">
             <div class="modal-body">
                <section class="checkMilkcodeMdl text-center">
                   
                    <h2 class="blueTitle pt-3">có gì đó không ổn!</h2>
                   
                    <button class="orangeBtn my-4" (click)="addTranscationForChild()" [disabled]="tryAgainForChild"> Thử lại</button>

                        <div class="blueCard mt-4">
                            <p class="topText"> {{ 'INVALIDQR_CODE1.CARD_TEXT_TOP' | translate }}</p>
                            <p class="colorBlackBold pt-2 mb-0">{{ 'INVALIDQR_CODE1.CARD_PARA' | translate }}</p>
                            <p class="colorBlackBold pt-0">{{ 'INVALIDQR_CODE1.CARD_PARA1' | translate }}</p>
                            <h2 class="blueTitle"><a class="telNum" href="tel:19001519">{{ 'INVALIDQR_CODE1.CARD_BOTTOM_NUMBER' | translate }}</a></h2>
                        </div>
                </section>
            </div>
        </div>
    </div>
</div>

<app-auth-footer [hidden]="showScanner"></app-auth-footer>
<app-nav-menu></app-nav-menu>

<div [hidden]="!showScanner">
  <div class="video-row" fxFlex="100">
    <video class="video" #video autoplay playsinline></video>
  </div>
  <div fxFlex="100" style="display: none">
    <canvas class="video" id="canvas" #canvas></canvas>
  </div>
</div>
